<template>
  <b-row> </b-row>
</template>

<script>
export default {
  async mounted() {
    try {
      const userInfo = await this.api.get(`/api/user`)
      this.$router.push({
        name: "users-profile-info",
        params: {
          user_id: userInfo.id,
        },
      })
    } catch (error) {
      console.error(error)
      return ""
    }
  },
}
</script>
